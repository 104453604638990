<template>
    <div class="container">
        <div
            class="alert alert-warning text-start mt-2"
            v-if="errorMessage"
            role="alert"
        >
            <span v-html="errorMessage" />
        </div>
        <div :class="['text-center', errorMessage ? 'mt-2' : 'mt-8']">
            <img
                :src="require(`../assets/images/pages/activity/bank-note.svg`)"
                :width="160"
                class="mt-0 mb-4"
                alt="Bank Note"
            >
        </div>
        <div v-if="acknowledgedPif">
            <h5
                class="text-center fw-light mx-2 mb-5"
                v-html="$t('pages.signOnBonus.acknowledged')"
            />
        </div>
        <div v-else>
            <h5
                class="text-center fw-light mx-2 mb-5"
                v-html="$t('pages.signOnBonus.description', { sourceName: signOnBonusSourceName, maxAmount: maxAmountInUSD })"
            />
            <div class="text-center">
                <div class="other-options mb-2">
                    <base-button @click="onClick">
                        Great, thank you!
                    </base-button>
                </div>
            </div>
            <div>
                <p
                    class="small text-muted text-center"
                    v-html="$t('pages.payItForwardTipping.terms')"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import BaseButton from '@/components/base/BaseButton'
    import format from '@/mixins/format'
    import { logger } from '@/utils/logger'
    import { navigation } from '@/mixins/navigation'
    export default {
        name: 'SignOnBonusRealEstateAgent',
        components: { BaseButton },
        mixins: [format, navigation],
        data: function () {
            return {
                loading: false,
                errorMessage: null,
                acknowledgedPif: false,
            }
        },
        mounted() {
            this.$logEvent('view_sign_on_bonus_real_estate_agent')
        },
        computed: {
            ...mapGetters(['signOnBonusAmount', 'signOnBonusSourceName', 'currentRootPath']),
            maxAmountInUSD: function () {
                return this.toFormattedUSDNoCents(this.signOnBonusAmount)
            },
        },
        methods: {
            onClick: function () {
                this.acknowledgedPif = true
                setTimeout(() => {
                    logger.info(`User acknowledged PIF, replace current route with ${this.currentRootPath}`)
                    this.returnToRoot()
                }, 2000)
            },
        },
    }
</script>

<style scoped></style>
